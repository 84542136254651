.defaultScoreViewGrid {
  display: grid;
  grid-gap: var(--spacing-16);
  margin-block-start: var(--spacing-32);
}

@media (--min-width-md) {
  .defaultScoreViewGrid {
    grid-template-columns: 1fr 2fr;
  }
}

.defaultScoreViewLegend {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
