.toggler {
  border-block-end: 1px dotted var(--color-text-hint); /** custom underline for text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableWrapper {
  width: 100%;
}

.table {
  width: 100%;
  color: var(--color-text-heading);
}

.table td,
.table th {
  padding-inline: var(--spacing-16);
  padding-block: var(--spacing-08);
  border-inline-end: 1px solid var(--color-border);
}

.table th {
  color: var(--color-text-body);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  text-align: start;
}

.row.current {
  background-color: var(--color-controls--selected-bg);
  position: relative;
}

.row.current ::before {
  content: '';
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 0.25rem;
  height: 100%;
  background-color: var(--color-controls--selected-marker);
}

.row:first-child td {
  padding-block-start: var(--spacing-16);
}

.percentileToggler {
  position: relative;
}

:global([data-theme='deuteranopia']) .row.current.top {
  background-color: rgba(51, 136, 255, 0.1);
}

:global([data-theme='deuteranopia']) .row.current.bottom {
  background-color: rgba(255, 181, 77, 0.1);
}
